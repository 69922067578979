@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,600);
@import url(https://fonts.googleapis.com/css?family=Cinzel:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Oswald);

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  opacity: 1;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-width: 1500px;
}

.navbar-logo {
  /* color:rgb(114,82,56); */
  color:rgb(202,181,150);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-family: "Cinzel", serif;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 70px;
}

.nav-links {
  color:rgb(202,181,150);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
}

.nav-links:hover {
  border-bottom: 4px solid rgb(202,181,150);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color:rgb(202,181,150);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color:rgb(202,181,150);
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color:rgb(202,181,150);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color:rgb(202,181,150);
    padding: 14px 20px;
    border: 1px solid rgb(202,181,150);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    color:rgb(202,181,150);
    color: #242424;
    transition: 250ms;
  }
}

/* Global Reset and Font Styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* Common Styling for Sections */
.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem; /* Consistent font size */
}

/* Services Section Styling */
.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px for readability */
}

/* Products Section Styling */
.products {
  background-position: center;
  background-size: cover; /* Changed from 'fill' to 'cover' for consistent scaling */
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px */
}

/* Sign-Up Section Styling */
.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px */
}

.hero-container {
    background-image: url(/static/media/background.0c817b1a.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: "Raleway", sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container{
      background-size: cover;
      background-position: center;
    }
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  
.footer-container {
    background-color: #242424;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color:rgb(202,181,150);
  }
  
  .footer-subscription > p {
    font-family: "Raleway", sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid rgb(202,181,150);;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color:rgb(202,181,150);
  }
  
  .footer-link-items a {
    color:rgb(202,181,150);
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color:rgb(202,181,150);
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color:rgb(202,181,150);
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 0px auto 0 auto;
    font-family: "Work Sans", sans-serif;
  font-weight: 600;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100px;
  }
  
  .social-logo {
    color:rgb(202,181,150);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color:rgb(202,181,150);
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }
  
.timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
}

/* Cards */
.card {
  flex: 0 0 auto;
  width: 350px;
  max-width: 50%;
  background-color: black;
  border: 1px solid rgb(202, 181, 150);
  border-radius: 8px;
  color: rgb(202, 181, 150);
  text-align: left;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-height: 550px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 460px;
  object-fit: cover;
  border-bottom: 1px solid rgb(202, 181, 150);
}

.card-content {
  padding: 1rem;
}

.card-title {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.card-subtitle {
  font-size: 1.2rem;
  margin: 0.3rem 0;
  color: rgb(255, 223, 186);
}

.card-description {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.card-date {
  font-size: 0.9rem;
  color: rgb(202, 181, 150);
}

/* Responsive adjustments */
@media only screen and (max-width: 960px) {
  .timeline {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: calc(100vh - 70px); /* Adjust based on your navbar height */
    padding: 1rem;
    justify-content: flex-start; /* Align items to the top */
  }

  .card {
    width: 90%;
    max-width: 350px;
    margin-bottom: 1rem;
    max-height: none; /* Remove max-height constraint */
  }

  .card-image {
    height: auto;
    max-height: 300px;
  }

  /* Ensure first card is fully visible */
  .timeline::before {
    content: '';
    display: block;
    height: 1rem; /* Add some space at the top */
  }
}

/* Further adjustments for very small screens */
@media only screen and (max-width: 480px) {
  .card {
    width: 95%;
  }
}

/* Hide scrollbars on mobile devices */
@media only screen and (max-width: 960px) {
  .timeline {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .timeline::-webkit-scrollbar {
    display: none;
  }
}
.cards {
    padding: 4rem;
    background: black;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    width: 100%
  }
  
  .cards__items {
    margin-bottom: 24px;
    
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
    
   
  }
  .cards__item2 {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
    box-shadow: 0 0 0 0.1em rgb(202,181,150);
   
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
   
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color:rgb(202,181,150);
    background-color: black;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: scale-down;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: rgb(202,181,150);
    font-size: 18px;
    line-height: 24px;
  }

  .button{
    background: black;
    cursor: pointer;
    border:none;
    position: 'absolute';
    top: '200px';
   
  }

  .imageGallery{

    padding: 0;
  }
  .button-span{
    font-size: xxx-large;
    color: rgb(202,181,150);
    
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  
/* About.css */
.about-page {
  background-color: #000;
  color: rgb(202, 181, 150);
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.about-page .biblio {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.about-page .button.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: rgb(202, 181, 150);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}



.about-page .imageBox {
  float: left;
  width: 300px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  shape-outside: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.about-page .imageBox img {
  width: 100%;
  height: auto;
  display: block;
}



.about-page h1 {
  color: rgb(255, 223, 186);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about-page p {
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: justify;
}

@media (max-width: 767px) {
  .about-page .imageBox {
    float: none;
    margin: 0 auto 2rem;
    shape-outside: none;
    clip-path: none;
  }
  
  .about-page .imageBox img {
    border-radius: 50%;
  }
}

@media (min-width: 1024px) {
  .about-page {
    padding: 4rem;
  }

  .about-page h1 {
    font-size: 2.5rem;
  }
}
