/* About.css */
.about-page {
  background-color: #000;
  color: rgb(202, 181, 150);
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.about-page .biblio {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.about-page .button.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: rgb(202, 181, 150);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}



.about-page .imageBox {
  float: left;
  width: 300px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  shape-outside: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.about-page .imageBox img {
  width: 100%;
  height: auto;
  display: block;
}



.about-page h1 {
  color: rgb(255, 223, 186);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about-page p {
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: justify;
}

@media (max-width: 767px) {
  .about-page .imageBox {
    float: none;
    margin: 0 auto 2rem;
    shape-outside: none;
    clip-path: none;
  }
  
  .about-page .imageBox img {
    border-radius: 50%;
  }
}

@media (min-width: 1024px) {
  .about-page {
    padding: 4rem;
  }

  .about-page h1 {
    font-size: 2.5rem;
  }
}