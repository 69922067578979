.timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
}

/* Cards */
.card {
  flex: 0 0 auto;
  width: 350px;
  max-width: 50%;
  background-color: black;
  border: 1px solid rgb(202, 181, 150);
  border-radius: 8px;
  color: rgb(202, 181, 150);
  text-align: left;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-height: 550px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 460px;
  object-fit: cover;
  border-bottom: 1px solid rgb(202, 181, 150);
}

.card-content {
  padding: 1rem;
}

.card-title {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.card-subtitle {
  font-size: 1.2rem;
  margin: 0.3rem 0;
  color: rgb(255, 223, 186);
}

.card-description {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.card-date {
  font-size: 0.9rem;
  color: rgb(202, 181, 150);
}

/* Responsive adjustments */
@media only screen and (max-width: 960px) {
  .timeline {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: calc(100vh - 70px); /* Adjust based on your navbar height */
    padding: 1rem;
    justify-content: flex-start; /* Align items to the top */
  }

  .card {
    width: 90%;
    max-width: 350px;
    margin-bottom: 1rem;
    max-height: none; /* Remove max-height constraint */
  }

  .card-image {
    height: auto;
    max-height: 300px;
  }

  /* Ensure first card is fully visible */
  .timeline::before {
    content: '';
    display: block;
    height: 1rem; /* Add some space at the top */
  }
}

/* Further adjustments for very small screens */
@media only screen and (max-width: 480px) {
  .card {
    width: 95%;
  }
}

/* Hide scrollbars on mobile devices */
@media only screen and (max-width: 960px) {
  .timeline {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .timeline::-webkit-scrollbar {
    display: none;
  }
}