/* Global Reset and Font Styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* Common Styling for Sections */
.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem; /* Consistent font size */
}

/* Services Section Styling */
.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px for readability */
}

/* Products Section Styling */
.products {
  background-position: center;
  background-size: cover; /* Changed from 'fill' to 'cover' for consistent scaling */
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px */
}

/* Sign-Up Section Styling */
.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem; /* Equivalent to 100px */
}
